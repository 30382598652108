import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Button from '@amzn/meridian/button';
import AsinImagePreview from '../../../AsinImagePreview';
import styles from '../../PromoForm.module.scss';
import FormField from '../../FormField';

const Main = (props) => {
  const {
    asinValidatorIsLoading,
    auditedStoreRegions,
    columns,
    onAsinImageSelect,
    onAsinAvailabilityRequested,
    uniqueAsins,
    startDate,
  } = props;

  const onAsinAvailabilityButtonPressed = () => {
    onAsinAvailabilityRequested(uniqueAsins, startDate);
  };

  return (
    <div className={styles.formGroup}>
      <div className={styles.formGroupWrapper}>
        <div className={styles.formGroupLabel}>
          <div className={styles.formGroupLabelWrapper}>
            <AsinImagePreview />
          </div>
        </div>
        <div className={styles.formGroupContent}>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField
                {...columns.ASINS}
                onAsinImageSelect={onAsinImageSelect}
                showCopyAllButton
                showClearAllButton
                showTagCounter
              />
            </div>
            <div className={styles.formControl}>
              <Button
                type="primary"
                onClick={onAsinAvailabilityButtonPressed}
                disabled={asinValidatorIsLoading}
                minWidth="100%"
              >
                Check ASIN Availability
              </Button>
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...columns.ASIN_IMAGE} />
            </div>
            <div className={styles.formControl}>
              <FormField
                {...columns.PICTURED_ASINS}
                overrideOptions={uniqueAsins}
                showSelectAll
              />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField
                {...columns.STORE_REGIONS}
                currentItems={auditedStoreRegions}
                showBadgeList
                showSelectAll
              />
            </div>
            <div className={styles.formControl}>
              <FormField {...columns.STORE_IDS} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...columns.PAGE} />
            </div>
            <div className={styles.formControl}>
              <FormField {...columns.ZONE_NUMBER} />
            </div>
            <div className={styles.formControl}>
              <FormField {...columns.VENDOR_MANAGER} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...columns.VERTICAL} />
            </div>
            <div className={styles.formControl}>
              <FormField {...columns.PRODUCT_CATEGORY} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...columns.TITLE} />
            </div>
            <div className={styles.formControl}>
              <FormField {...columns.DESCRIPTION} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...columns.PROMOTION_TYPE} />
            </div>
            <div className={styles.formControl}>
              <FormField {...columns.CIRCULAR_PRICE} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...columns.UNIT_OF_MEASURE} />
            </div>
            <div className={styles.formControl}>
              <FormField {...columns.PRICE_CONDITIONS} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...columns.PRIME_DISCOUNT} />
            </div>
            <div className={styles.formControl}>
              <FormField {...columns.PRIME_MEMBER_LABEL} />
            </div>
            <div className={styles.formControl}>
              <FormField {...columns.DISCOUNT_TAG} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...columns.EACH_PRICE_FOR_F3RS} />
            </div>
            <div className={styles.formControl}>
              <FormField {...columns.PAWS_ID} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...columns.CIRCULAR_PRICE_DISPLAY} />
            </div>
            <div className={styles.formControl}>
              <FormField {...columns.PRIME_MEMBER_LABEL_DISPLAY} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...columns.IS_PRIME_BENEFIT} />
            </div>
            <div className={styles.formControl}>
              <FormField {...columns.IS_PRIVATE_BRAND} />
            </div>
            <div className={styles.formControl}>
              <FormField {...columns.IS_FRESH_BRAND} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...columns.IS_APLENTY} />
            </div>
            <div className={styles.formControl}>
              <FormField {...columns.IS_USDA_CHOICE} />
            </div>
            <div className={styles.formControl}>
              <FormField {...columns.IS_USDA_ORGANIC} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...columns.IS_COVER_PAGE_HERO} />
            </div>
            <div className={styles.formControl}>
              <FormField {...columns.IS_CATEGORY_HERO} />
            </div>
            <div className={styles.formControl}>
              <FormField {...columns.IS_LOCAL} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={styles.formControl}>
              <FormField {...columns.PROMOTION_END_DATE} />
            </div>
          </div>
          <div className={styles.formGroupSection}>
            <div className={classnames(styles.formControl, styles.fullSizeFormControl)}>
              <FormField {...columns.NOTES} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;

Main.propTypes = {
  uniqueAsins: PropTypes.arrayOf(PropTypes.string),
  startDate: PropTypes.string,
  asinValidatorIsLoading: PropTypes.bool.isRequired,
  onAsinAvailabilityRequested: PropTypes.func.isRequired,
  onAsinImageSelect: PropTypes.func.isRequired,
};

Main.defaultProps = {
  uniqueAsins: [],
  startDate: '',
};
